<template>
  <nav class="navbar navbar-header navbar-expand-sm navbar-dark bg-dark fixed-top ps-3 pe-2">   

    <img src="@/assets/img/Logotip_CONTROLMOTION.svg" class="ininitus-hd-logo" title="Logo CMA" />
    
    <div v-if="isLogedInUserView" class="collapse navbar-collapse" id="navbarsCollapseDiv"></div>

    <div v-if="isLogedInUserView" id="userbox" class="userbox ml-auto" style="margin-right:30px;">
      <a ref="dropdown" href="#" data-bs-toggle="dropdown" @click.prevent="changeFontClass" aria-expanded="false">
        <figure class="profile-picture">
          <img :src="this.userImg" class="rounded-circle" :data-lock-picture="this.userImg" :style="this.userImgStyle">
        </figure>
        <div class="profile-info" data-lock-name="{{ this.headName }}" data-lock-email="{{ this.headEmail }}" style="margin-right:15px !important;">
          <span class="name menu-dark">{{ this.headName }}</span>
          <span class="role menu-dark">{{ this.headEmail }}</span>
        </div>

        <i class="fa custom-caret menu-dark"></i>
      </a>

      <div class="dropdown-menu" style="min-width:140px;">
        <ul class="list-unstyled mb-2">
          <li  class="divider"></li>
          <li>
            <a role="menuitem" tabindex="-1" @click.prevent="changePassword" style="cursor:pointer !important;"><i class="fa-solid fa-hashtag"></i> Change password</a>
          </li>
          <li>
            <a role="menuitem" tabindex="-1" @click.prevent="logOut" style="cursor:pointer !important;"><i class="fa-solid fa-power-off"></i> Logout</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import ChangePasswordModal from '@/Modules/User/ChangePasswordModal'
//VDynamicAdvacedModal

export default {
  name: 'AppHeader',
  props: {
    isLogedInUserView: Boolean
  },
  beforeMount () {
    if(this.$app.darkTheme) {
      this.userImg = this.imgUserDarkSrc;
      this.userImgStyle = 'opacity:0.8';
    } else {
      this.userImg = this.imgUserWhiteSrc;
      this.userImgStyle = 'filter:grayscale(75%)';
    }
  },
  mounted () {
    let _user = this.$store.state.auth.user; 
    if (_user) {
      this.headName = _user.username;
      if(this.$app.user.isCustomerUser())
        this.headEmail = this.$app.user.customerName;
      else
        this.headEmail = (_user.username!==_user.email) ? _user.email : '';
    
      this.isLabUser = ( this.$app.$data.Lab22Users.indexOf(_user.email) == -1 ) ? false : true;
    }
    
    let _this = this;
    _this.timerId = setInterval(function() {
      _this.viewHistory = _this.$app.$data.viewHistory;
    }, 1000);
  }, 
  unmounted() {
    clearInterval(this.timerId);
  },
  data() {
    return {
      progressQueue: [],
      show: false,
      debugMode: this.$app.debugModeEnabled,
      headName: null,
      headEmail: null,
      authUser: this.$app.user,
      imgUserWhiteSrc: require('../../assets/img/logged-user.jpg'),
      imgUserDarkSrc: require('../../assets/img/user-acc.svg'),
      userImg: null,
      userImgStyle: null,
      viewHistory: this.$app.$data.viewHistory,
      isLabUser: false,
      timerId: null,
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user; 
    }
	},
  methods: {
    showAlert() {
      this.$app.$modal.alert('Info', 'Alert text...', () => {
        this.$app.notify('Closed');
      });
    },
    showConfirm() {
      this.$app.$modal.confirm('Confirm', 'Please confirm text...', (confirmed) => {
        this.$app.notify(confirmed ? 'Confirmed' : 'Not confirmed');
      });
    },
    showPrompt() {
      console.log('showPrompt');
    },
    showProgress() {
      console.log('shoshowProgresswConfirm');
    },
    showWait() {
      console.log('showWait');
    },
    showNotification() {
      console.log('showNotification');
    },
    startProgress() {
      this.progressQueue.push(this.$progress.start());
    },
    stopProgress() {
      const progress = this.progressQueue.pop();
      if (progress) 
        progress.finish();
    },
    showModalExample() {
      this.$app.$modal.advancedDynamicExample();
    },
    showToast(type) {
      this.$app.$helper.notify('Showing toast...', type, 2000);
    },
    logOut() {
      // this.$store.dispatch('auth/logout');
      // this.$router.go("/login");
      this.$app.logOut();
    },
    userDetails() {
      this.$router.push({
        name: 'user-details',
        params: { 
          data: JSON.stringify(this.$store.state.auth.user)
        }
      });
    },
    changePassword() {
      this.$vfm.show({
        component: ChangePasswordModal,
        bind: {
          'editRec': this.$store.state.auth.user
        },
        on: {
          ok: (data) => {
            this.$app.$helper.notify('Password was successfully changed.', 'info', 3000);
          }
        }
      });
    },
    onDarkThemeCheck(e) {
      this.$app.toggleDarkTheme(e.target.checked);
    },
    routeToDevice(guid) {
      const linkElement = document.getElementById('a-toggle-history-icon');
      if (linkElement)
        linkElement.click();

      setTimeout(() => {
        this.$router.push('/devices/' + guid + '/details');
      }, 100);
    },
    toggleHistoryIcon() {
      var icon = document.getElementById('flippableHistoryIcon');
          icon.classList.toggle('flipped');
    },
  }
}
</script>

<style scoped>

.flipped {
    transform: rotate(180deg);
    transition: transform 0.5s ease;
}

.navbar {
  height: 60px;
}

.navbar-brand {
  line-height:6px;
}

.brand-name {
  font-weight: bold;
  font-size: 15px;
  padding-left:3px;
}

.app-name {
  font-size: 12px;
  letter-spacing: 4px;
  color: rgba(255,255,255,.7)
}

.menu-dark {
  color: #7d7d7d !important;
}

.main-search {  
  height: 32px;
  line-height: 32px;
}

/* makes sub-menu S open on hover */
.submenu-item:hover > .dropdown-menu {
  display: block;
}

.ininitus-hd-logo {
  height: 45px;
}

/*.customer-name {
  text-indent: 45px;
  font-size: 14px;
}*/

.dropdown-menu .form-check {
  padding-left: 32px;
  margin-top: 15px;
}

.dropdown-menu .form-check input {
  margin-top: 2px;
  margin-right:0px
}

.dropdown-menu .form-check .form-check-label {
  padding-left: 0px;
  font-size: 12px;
}

.dropdown-menu .form-check .form-check-label {
  color: #7d7d7d;
}

html.dark .dropdown-menu .form-check .form-check-label {
  color: #c3c3c3;
}

html.dark .userbox:after {
  background: #262626;
}

</style>