<template>
  <div class="lab22-mv-padding">
    
    <!--<ModuleHeader title="Analytics" iconClass="fa fa-square-poll-vertical">-->
    <ModuleHeader title="Analytics" iconClass="inf-module-header-analytics inf-module-header-img">
      <div v-show="isLabUser" class="etl-status">
        <div class="text-nowrap">
          <label>ETL status:</label> {{store.state.Reporting.etl.dbStatus}}
        </div>
        <div class="text-nowrap">
          <label>Last refresh:</label> {{store.state.Reporting.etl.dbTimestamp}}
        </div>
      </div> 
      <BaseButton @click="showAddMetricModal()" text="Add metric" />
    </ModuleHeader>
    <FilterBar :filterState="getState().filter" @change="chartDirty = true" @reload="load()" showReload secondaryColors :loading="loading" :dirty="chartDirty" :hideFilterByLabel="true">
      <template  v-slot="{state}">
        <label>Period from</label>
        <BaseField v-model="state.dateFrom" name="dateFrom" required @change="onPeriodChange" type="datetime-local" style="width:160px;" />
        <label style="padding-left:5px;">to</label>
        <BaseField v-model="state.dateTo" name="dateTo" required @change="onPeriodChange" type="datetime-local" style="width:160px;" />
        <label v-show="false" style="padding-left:10px;">Time axis</label>
        <BaseField v-show="false" v-model="state.minutesRound" name="minutesRound" as="select" readonly :data="this.minutesRoundOptions" style="width:110px" />        
      </template>
    </FilterBar>
    <div ref="bodyEl" class="d-flex flex-column align-items-stretch">
      <div style="height:65%;padding-top:10px;padding-bottom:40px;">
        <div class="d-flex">
          <BaseCheckbox :checked="getState().autoScaleYAxis" style="margin-left:18px" label="auto scale y-axis" @checked="onAutoScaleYaxisChange" />
          <BaseCheckbox :checked="getState().showAlerts" style="margin-left:15px" label="show alerts" @checked="onShowAlertsChange" />
        </div>        
        <apexchart
          type="area"
          ref="chart" 
          height="100%"
          class="analytics-chart"
          :series="chartSeries"
          :options="chartOptions" 
        />
      </div>
      <MetricsView 
        ref="metricView" 
        :state="getState()"
        @edit-metric="editMetric"
        style="height:35%"
      />
      <!--
      <MetricsView 
        ref="metricView" 
        :state="getState()"
        @add-metric="load()"
        @remove-metric="load()"        
        @metric-color-change="load()"
        @metric-y-axis-change="load()"
        @edit-metric="editMetric"
        style="height:35%"
      />        
      -->      
    </div>
  </div>
</template>

<script>
import _app from '@/App/App';
import ModuleHeader from '@/App/components/Common/ModuleHeader';
import FilterBar from '@/App/components/Common/FilterBar';
import BaseField from '@/App/components/Form/BaseField';
import dayjs from 'dayjs';
import MetricsView from './MetricsView'
import BaseButton from '@/App/components/Common/BaseButton'
import AddMetricModal from './AddMetricModal'
import BaseCheckbox from '@/App/components/Form/BaseCheckbox'
import AlertDetailsModal from '@/Modules/Alerts/AlertDetailsModal'

const _yAxisLabelsFormatter = function(val) {
  try {
    if (!val || val % 1 == 0)
      return val;

    if (val === Number.MIN_VALUE)
      return 0;
    
    if (val < 100)
      return Math.round((val + Number.EPSILON) * 100) / 100;

    return (val).toFixed(0);
  } catch(err) {
    return val;
  }
};

const _tooltipValueFormatter = function(val) {
  try {
    if (!val)
      return val;
    return val % 1 == 0 ? (val).toFixed(0) : val;
  } catch(err) {
    return val;
  }
};

const _periodRoundOptions = [
  { id: 5, name: '5 minutes'},
  { id: 10, name: '10 minutes'},
  { id: 15, name: '15 minutes'},
  { id: 30, name: '30 minutes'},
  { id: 60, name: '1 hour'}
];

export default {
  name: 'AnalyticsView',
  components: {
    ModuleHeader,
    FilterBar,
    BaseField,
    MetricsView,
    BaseButton,
    BaseCheckbox
  },
  data: function() {

    const _darkTheme = _app.darkTheme;

    return {
      user: this.$store.state.auth.user,
      store: this.$store,
      isLabUser: false,
      loading: false,
      minutesRoundOptions: _periodRoundOptions,
      chartOptions: {
        chart: {
          type: 'area',
          stacked: false,
          zoom: {
            type: 'x',
            enabled: true
            // autoScaleYaxis: true
          },
          animations: {
            enabled: false
          },          
          toolbar: {
            tools: {
              download: '<i class="fa-solid fa-download">',
              //selection: '<i class="fa-solid fa-download">',
              zoom: '<i class="fa-solid fa-magnifying-glass">',
              zoomin: false, //'<i class="fa-solid fa-magnifying-glass-plus">',
              zoomout: false, //'<i class="fa-solid fa-magnifying-glass-minus">',
              pan: '<i class="fa-solid fa-hand">',
              reset: '<i class="fa-solid fa-house">'
            },
            export: {
              csv: {
                headerCategory: 'Date,Time',
                dateFormatter: function(timestamp) {
                  return _app.formatDate(timestamp, 'DD.MM.YYYY') + ',' + _app.formatDate(timestamp, 'HH:mm:ss');
                }
              }
            }
          }   
        },        
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0
        },
        legend: {
          show: false
        },        
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100]
          },
        },
        xaxis: {
          type: 'datetime',
          labels: {
            datetimeUTC: false,
            style: {
              colors: _darkTheme ? '#bbc5d6' : undefined
            }
          },
          axisBorder: {
            color: _darkTheme ? '#bbc5d6' : '#d8dee4'
          },
          axisTicks: {
            color: _darkTheme ? '#bbc5d6' : '#d8dee4'
          }
        },
        yaxis: {
          labels: {          
            formatter: _yAxisLabelsFormatter,            
            style: {
              colors: _darkTheme ? '#bbc5d6' : undefined
            }
          }
        },
        grid: {
          borderColor: _darkTheme ? '#3e424a' : '#d8dee4'
        },
        tooltip: {
          shared: false, // ne dela ker se niso enake x točke
          theme: _darkTheme ? 'dark' : 'light',
          x: {
            format: 'dd.MM HH:mm'
          },
          y: {
            title: {
              formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
                //console.log(series, seriesIndex, dataPointIndex, w)
                return this.chartSeries[seriesIndex].serieName;
              }
            },
            formatter: _tooltipValueFormatter
          }
        },
        // stroke: {
        //   curve: 'straight'
        //   curve: 'smooth',
        //   dashArray: [3, 3, 3, 3, 3]        
        // },        
        noData: { text: 'No data' }       
      },
      chartSeries: [],
      chartDirty: false,
      oldMinutesRound: null,
      timerId: null,
    }
  },
  mounted() {

    this.isLabUser = (this.user && _app.$data.Lab22Users.indexOf(this.user.email) !== -1) ? true : false;
    
    if(this.isLabUser) {
      this.fetchEtlStatus();

      let _this = this;
          _this.timerId = setInterval(function() {
            _this.fetchEtlStatus();
          }, 60 * 1000);
    }

    const state = this.getState();

    this.doLayout();

    window.addEventListener('resize', this.doLayout);
    
    if (this.$route.params.data) {
      
      const data = JSON.parse(this.$route.params.data);

      if (data.dateFrom && data.dateTo) {
        state.filter.dateFrom = _app.formatDate(data.dateFrom, _app.ISO_FORMAT, 'local');
        state.filter.dateTo = _app.formatDate(data.dateTo, _app.ISO_FORMAT, 'local');
      } else {
        state.filter.dateFrom = dayjs().startOf('day').format(_app.ISO_FORMAT);
        state.filter.dateTo = dayjs().startOf('day').add(1, 'day').format(_app.ISO_FORMAT);
      }

      if (data.metric) {    
        if (Array.isArray(data.metric)) {          
          for(let i = 0; i < data.metric.length; i++) {
            this.$refs.metricView.addMetric(data.metric[i], data.removeExisting && i == 0);  
          }
        } else {
          if (data.metric.metricId) {
            this.$refs.metricView.addMetric(data.metric, data.removeExisting);
          } else {
            this.showAddMetricModal(data.metric);
          } 
        }
      }
    }
    else if (state.metrics.length > 0) {
      this.load();      
    } else {
      // this.showAddMetricModal(); 
    }      
    this.$emit('set-main-view-class', 'overflow-visible');

    this.onPeriodChange();
  },
  unmounted() {
    clearInterval(this.timerId);
    window.removeEventListener("resize", this.doLayout);
    this.$emit('set-main-view-class', '');
  },  
  methods: {    
    fetchEtlStatus() {
      this.store.dispatch('fetchEtlStatus', {customerId:-1, dateFrom:'2000-01-01', dateTo:'2999-12-31', table:'ETL_DB_INFO' });
      if(this.store.state.Reporting.etl.dbStatus=='PROCESSING')
        _app.$helper.notifyInfo('ETL process is currently running. This may result decreased query performance');
    },
    getState() {
      return _app.$data.getState('AnalyticsState', {
        filter: {
          dateFrom: dayjs().startOf('day').format(_app.ISO_FORMAT),
          dateTo: dayjs().startOf('day').add(1, 'day').format(_app.ISO_FORMAT),
          minutesRound: 60
        },
        metrics: [],
        autoScaleYAxis: true,
        showAlerts: true
      });
    },
    doLayout() {
      this.$refs.bodyEl.style.height = (window.innerHeight - (this.$refs.bodyEl.getBoundingClientRect().top + document.documentElement.scrollTop)) + "px";
    },
    load() {
      const groupDevices = false;
      const limit = null;
      
      const state = this.getState();

      this.loading = true;

      this.chartDirty = false;      

      const exportCsvHeaderItems = ['Date','Time'];

      // group metrics by devices
      const deviceMeaGroupDic = {};    

      for (let i = 0; i < state.metrics.length; i++) {
        const metric = state.metrics[i];

        // GROUPING MEASUREMENT PER DEVICE IS DISABLED FOR NOW
        // to group measurements per device, remove metricId part from key
        var key = metric.deviceId + '_' + metric.metricId; 

        let deviceMeaGroup = deviceMeaGroupDic[key];  
        if (!deviceMeaGroup) {
          deviceMeaGroup = {
            evTypeId: metric.evTypeId,
            deviceId: metric.deviceId,
            measurements: []
          }
          deviceMeaGroupDic[key] = deviceMeaGroup;
        }
        deviceMeaGroup.measurements.push(metric.metricId);

        exportCsvHeaderItems.push('"' + metric.deviceName + ' - ' + metric.metricName + '"');
      }

      var requests = [];

      for (let key in deviceMeaGroupDic) {
        const deviceMeaGroup = deviceMeaGroupDic[key];
        requests.push(
          _app.$api.get('Analytics/GetAnalyticsDataTable', { 
            params: { 
              eventTypeId: deviceMeaGroup.evTypeId, 
              devices: deviceMeaGroup.deviceId, 
              measurements: deviceMeaGroup.measurements.join(','),  
              dateFrom: this.$app.toUtcDate(state.filter.dateFrom), 
              dateTo: this.$app.toUtcDate(state.filter.dateTo), 
              minutesRound: 60, //state.filter.minutesRound, 
              groupDevices: groupDevices,
              limit: limit 
            } 
          })
        );
      }

      let _this = this;

      const autoScaleYAxis = state.autoScaleYAxis;

      Promise.all(requests).then(
        (results) => {

          if (state.showAlerts)
            this.loadAlerts();
          else
            this.loading = false;

          this.chartSeries = [];

          let leftAxisSeriesCount = 0, rightAxisSeriesCount = 0;

          const chartOptions = {
            colors: [], 
            yaxis: []            
          };

          const chartSeries = [];          

          // create metric dic for lookup
          const metricDic = Object.assign({}, ...this.getState().metrics.map((i) => ({[i.deviceId + '_' + i.metricId]: i})));

          for(let i = 0; i < results.length; i++) {

            const data = results[i].data;

            const reqParams = results[i].config.params;

            const deviceId = reqParams.devices;

            if (data.length == 0)
              continue;

            const dataKeys = Object.keys(data[0]);

            if (dataKeys.length < 2)
              continue;

            // first key is date, other are measures
            const dateKey = dataKeys[0];
            
            // for each measurement in result add serie
            for(let meaKeyIdx = 1; meaKeyIdx < dataKeys.length; meaKeyIdx++) {

              const meaKey = dataKeys[meaKeyIdx];

              const meaKeySplit = meaKey.split('_');

              if (!(meaKeySplit.length == 2 && meaKeySplit[0] === 'm'))
                continue; // not measurement key, ignore it

              const meaId = meaKeySplit[1];

              // get result measurement metric
              const metric = metricDic[deviceId + '_' + meaId];

              if (!metric)
                continue;

              // transmorm data to x, y serie data, '.000Z' -> UTC
              const serieData = this.addMissingValues(data.map(i => { return { x: new Date(i[dateKey] + '.000Z').getTime(), y: i[meaKey] } }));

              // set color
              chartOptions.colors.push(metric.color);

              // set left/right y axis
              const rightAxis = metric.yAxis == 2;

              if (rightAxis) {
                rightAxisSeriesCount++;
              } else {
                leftAxisSeriesCount++;
              }

              const seriesAxisName = rightAxis ? 'RIGHT_AXIS' : 'LEFT_AXIS';

              // add serie
              chartSeries.push({
                name: seriesAxisName,
                serieName: metric.deviceName + ': ' + metric.metricName,
                data: serieData,
                rightAxis: rightAxis
              });

              // add serie y axis
              chartOptions.yaxis.push({
                seriesName: seriesAxisName,
                opposite: rightAxis,
                show: (rightAxis && rightAxisSeriesCount == 1) || (!rightAxis && leftAxisSeriesCount == 1),
                labels: {
                  formatter: _yAxisLabelsFormatter,
                  style: {
                    colors: _app.darkTheme ? '#bbc5d6' : undefined
                  }
                },
                min: autoScaleYAxis ? undefined : 0,
                forceNiceScale: true
              });
            }
          }

          /*
          leftAxisSeriesCount++;

          chartOptions.colors.push('#000000');

          let chartDateTo = new Date(state.filter.dateTo);
          
          if (chartDateTo > new Date())
            chartDateTo = new Date();

          chartSeries.push({  
            name: 'LEFT_AXIS',
            serieName: '',
            rightAxis: false,
            data: [
              {
                x: new Date(state.filter.dateFrom).getTime(),
                y: null
              },
              {
                x: chartDateTo.getTime(),
                y: null
              }
            ]            
          });

          chartOptions.yaxis.push({
            seriesName: 'LEFT_AXIS',
            opposite: false,
            show: false
          });          
          */
         
          if (chartSeries.length) {
            
            if (rightAxisSeriesCount == 0 || leftAxisSeriesCount == 0) {
              chartOptions.yaxis = chartOptions.yaxis.filter(i => i.show);
            }

            chartOptions.chart = {
              zoom: {
                // autoScaleYaxis: chartOptions.yaxis.length == 1
              },      
              toolbar: {
                export: {
                  csv: {
                    headerCategory: exportCsvHeaderItems
                  }
                }
              }              
            }              
            
            // chartOptions.chart = {
            //   animations: {
            //     enabled: chartSeries.length < 3
            //   }
            // }

            this.chartOptions = {...this.chartOptions, ...chartOptions};

            this.chartSeries = chartSeries;
          }
        },
        () => {
          this.loading = false;
        }
      );
    },
    loadAlerts() {

      const state = this.getState();

      var requests = [];

      for (let i = 0; i < state.metrics.length; i++) {
        
        const metric = state.metrics[i];

        requests.push(
          _app.$api.get('Analytics/GetAnalyticsAlerts', { 
            params: { 
              deviceId: metric.deviceId, 
              measurementId: metric.metricId, 
              dateFrom: this.$app.toUtcDate(state.filter.dateFrom), 
              dateTo: this.$app.toUtcDate(state.filter.dateTo)
            } 
          })
        );        
      }

      this.loading = true;

      Promise.all(requests).then(        
        (results) => {

          this.loading = false;

          const chartOptions = {
            annotations: {
              xaxis: []
            }
          };          

          const alerts = [];

          for(let i = 0; i < results.length; i++) {
            const resultData = results[i].data;
            for (let j = 0; j < resultData.length; j++) {
              alerts.push(resultData[j])
            }
          }          

          let offsetY = -8;
          let OffsetYStep = alerts.length > 1 ? Math.floor(this.$refs.chart.$el.getBoundingClientRect().height / alerts.length) : 0;

          for(let i = 0; i < alerts.length; i++) {

            const alert = alerts[i];

            const labelText = `${alert.conditionText}`;

            let  offsetY = (i * OffsetYStep) - 8;

            chartOptions.annotations.xaxis.push({
              x: new Date(alert.created + '.000Z').getTime(),
                borderColor: '#FF19FF',
                strokeDashArray: 0,             
                label: {
                  show: true,
                  borderRadius: 0,
                  orientation: 'horizontal',
                  offsetX: 5,
                  offsetY: offsetY,
                  textAnchor: 'start',
                  text: labelText,                    
                  style: {                      
                    color: "#fff",
                    background: '#FF19FF',
                    fontSize: '13px',
                    cssClass: 'apexcharts-xaxis-annotation-label'
                  },
                  click: (anotation, e) => {
                    this.showAlertDetails(anotation, e)
                  },
                  mouseEnter: (anotation, e) => {
                    
                    if (e.srcElement.getElementsByTagName('title').length == 0) {

                      let titleEl = document.createElementNS("http://www.w3.org/2000/svg", "title");

                      titleEl.textContent = anotation.alert.deviceName;

                      e.srcElement.appendChild(titleEl);                                          
                    }

                    try {
                      e.srcElement.style.cursor = 'pointer';
                    } catch(err) { console.log(err); }
                  },                  
                  // mouseLeave: (anotation, e) => {
                  //   e.srcElement.nextSibling.textContent = `${anotation.alert.conditionText}`;
                  // }
                },
                alert: alert,
                index: i
            });
          }

          this.chartOptions = {...this.chartOptions, ...chartOptions};
        },
        () => {
          this.loading = false;
        }
      );
    },
    hideAlerts() {
      const chartOptions = {
        annotations: {
          xaxis: []
        }
      };  

      this.chartOptions = {...this.chartOptions, ...chartOptions};
    },
    editMetric(rec) {
      this.$vfm.show({
        component: AddMetricModal,
        bind: {
          'editRec': rec
        },        
        on: {
          ok: (data) => {
            //this.load();  
          }
        }
      });
    },
    showAlertDetails(anotation, e) {
      this.$vfm.show({
        component: AlertDetailsModal,
        bind: {
          'id': anotation.alert.id
        }
      });       
    },
    showAddMetricModal(initialData) {
      this.$vfm.show({
        component: AddMetricModal,
        bind: {
          'initialData': initialData
        },        
        on: {
          ok: (data) => {
            this.$refs.metricView.addMetric(data);
          }
        }
      });
    },
    onPeriodChange() {

      const state = this.getState();

      if (dayjs(state.filter.dateFrom).add(3, 'day').$d < new Date(state.filter.dateTo)) {
        this.minutesRoundOptions = [
          { id: 60, name: '1 hour'}
        ];
        if (state.filter.minutesRound != 60) {

          this.oldMinutesRound = state.filter.minutesRound;

          state.filter.minutesRound = 60;
        }        
      } else {
        this.minutesRoundOptions = _periodRoundOptions;

        if (this.oldMinutesRound && state.filter.minutesRound != this.oldMinutesRound)
          state.filter.minutesRound = this.oldMinutesRound;
      }
    },
    onAutoScaleYaxisChange(checked) {

      this.getState().autoScaleYAxis = checked;

      const enableAutoScale = this.getState().autoScaleYAxis;

      for (let i = 0; i < this.chartOptions.yaxis.length; i++) {
        if (enableAutoScale) {
          delete this.chartOptions.yaxis[i].min;
        } else {
          this.chartOptions.yaxis[i].min = 0
        }        
      }
      
      this.chartOptions = {...this.chartOptions};
    },
    onShowAlertsChange(checked) {
      
      this.getState().showAlerts = checked;

      if (checked) {
        this.loadAlerts();
      } else {
        this.hideAlerts();
      }
    },
    addMissingValues(data) {
      
      const serieData = [];

      if (data.length == 0) 
        return data;

      const stepMs = this.getState().filter.minutesRound * 60 * 1000;

      let nextPointX = data[0].x;

      for(let i = 0; i < data.length; i++) {
        
        const point = data[i];
        const pointX = point.x;

        while (nextPointX < pointX) {
          serieData.push({ x: nextPointX, y: null });  
          nextPointX += stepMs;
        }

        serieData.push(point)

        nextPointX = point.x + stepMs;
      }

      return serieData;
    }  
  }
}
</script>

<style scoped>
.lab22-mv-padding {
  padding-right:25px;
}

.etl-status {
  width: 200px;
  margin-top: -9px;
  margin-right: 8px;
  color: #ffffff !important;
}

.etl-status label {
  color: #bbc5d6 !important;
  width: 75px;
}
</style>

<style>

.analytics-chart .fa-solid {
  color:#bbc5d6;
}

.analytics-chart .apexcharts-toolbar {
  margin-top:-20px !important;
}

.apexcharts-toolbar .fa-magnifying-glass-plus,
.apexcharts-toolbar .fa-magnifying-glass-minus {
  font-size:23px;
}

.apexcharts-toolbar .fa-hand {
  font-size:24px;
  margin-top: -1px;
  margin-left: -2px;
}

.apexcharts-toolbar .fa-house,
.apexcharts-toolbar .fa-download {
  font-size:18px;
  margin-top:opx;
}

.apexcharts-toolbar .fa-house {
  margin-left: -2px;
}


.apexcharts-toolbar .fa-magnifying-glass {
  margin-top:1px;
  font-size:18px;
}

/* .metrics-grid th {
  color: #adadad;
  font-weight: normal;
} */

</style>