import _app from '@/App/App'
import dayjs from 'dayjs';
//import Vue from 'vue'
import { createStore } from 'vuex'
import { auth } from "./auth.module";

const _helper = {

  formatUtcDate: function (date) {
    let format = 'DD.MM.YY HH:mm:ss',
        d = dayjs(date);
        d = d.add(d.utcOffset(), "minute");
    return d.format(format);  
  },

};

const store = createStore({
    state: {
      Reporting: {
          etl: {
            dbStatus: '',
            dbTimestamp: ''
          },
          workspaces: [],
          models: [],
          reports: [],
          currentWsDirty: false,
          scrollOffset: 0,
      },
    },
    mutations: {
      FETCH_ETL_STATUS (state, payload) {
        state.Reporting.etl.dbStatus = payload.dbStatus;
        state.Reporting.etl.dbTimestamp = payload.dbTimestamp;
      },
      updateMainScrollOffset (state, offset) {
        state.Reporting.scrollOffset = offset;
      },
    },
    actions: {
      fetchEtlStatus({commit}, payload) { //get etl status info
        _app.$api.get('Dashboard/GetDashboardTable', { params: payload }).then(
          result => {
            const _res = result.data[0],
                  _data = {dbStatus:_res.etl_status, dbTimestamp:_helper.formatUtcDate(_res.etl_last_refresh)};
            commit("FETCH_ETL_STATUS", _data);
          },
          error => {
            _app.handleError(error);
          }
        );
      },
    },
    getters: {
        getScrollOffset(state) {
          return state.Reporting.scrollOffset;
        },
    },
    modules: {
        auth
    },
});

export default store;