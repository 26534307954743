<template>
  <div class="lab22-mv-padding">
    <!--<ModuleHeader iconClass="fa-solid fa-tablet-screen-button" :title="data?.name" :subtitle="data?.address" @titleClick="load(true)">-->
    <ModuleHeader :title="data?.name" :subtitle="data?.address" @titleClick="load(true)"  @click.shift.ctrl.prevent="deviceInfo(data?.id)" iconClass="inf-module-header-devices inf-module-header-img">
      <div class="connected-status">
        <div class="text-nowrap">
          <label>Connected:</label>{{ this.$app.$data.getConnectedStatusName(data?.connStatusId)}}
        </div>
        <div class="text-nowrap">
          <label>Last event:</label>{{ data?.lastEvent ? this.$app.formatDate(data?.lastEvent) : '-' }}
        </div>
      </div>    
      <div class="d-flex">
        <div class="btn-group mx-2">
          <button type="button" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">{{statusName}}</button>
          <ul class="dropdown-menu dropdown-menu-end">
            <li v-for="status in statusOptions" :key="status.id"><a class="dropdown-item" :class="{ active: status.id == data?.statusId }" @click.prevent="setStatus(status.id)">{{status.name}}</a></li>
          </ul>
        </div>
        <div class="btn-group">
          <!--<button type="button" class="btn btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa-solid fa-gear"></i></button>-->
          <button type="button" class="btn btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="svg-settings"></i></button>
          <ul class="dropdown-menu dropdown-menu-end">
            <h6 class="dropdown-item-text" style="white-space: nowrap;"><label>Client version:</label> {{ clientVersion }}</h6>
            <div class="dropdown-divider"></div>
            <li><a class="dropdown-item" @click.prevent="downloadConfiguration()"><i class="fa-solid fa-download"></i>Download configuration</a></li>
            <li><a class="dropdown-item" @click.prevent="showOnMap()"><i class="fa-solid fa-location-dot"></i>Show on map</a></li>            
            <li><hr class="dropdown-divider"></li>
            <li><a class="dropdown-item" @click.prevent="deleteDevice()"><i class="fa-solid fa-trash-can"></i>Delete</a></li>
          </ul>
        </div>
      </div>
    </ModuleHeader>
    <div class="tabs">
      <ul class="nav nav-tabs">
        <li class="nav-item" v-for="tab in tabs" :key="tab">
          <router-link class="nav-link" active-class="active" :to="tab.route">{{tab.name}}</router-link>
        </li>
      </ul>    
    </div>
    <router-view ref="routerView" :device="data" @refreshStatus="refreshStatus"></router-view>
    
  </div>
</template>

<script>
import _app from '@/App/App'
import ModuleHeader from '@/App/components/Common/ModuleHeader'
import domtoimage from 'dom-to-image-more';

export default {
  name: 'DeviceDetails',
  props:['guid'],
  components: {
    ModuleHeader,
  },    
  data() {
    return {
      data: null,
      statusOptions: [
        { id: _app.$data.DeviceStatus.Active, name: 'Paired'},
        { id: _app.$data.DeviceStatus.Maintenance, name: 'Maintenance'},
        { id: _app.$data.DeviceStatus.Deactivated, name: 'Deactivated'},
        { id: _app.$data.DeviceStatus.OutOfOrder, name: 'Out of order'}
      ],
      statusName: '',
      checkedAlerts: [],
      clientVersion: null,
      isLabUser: false,
    }
  },
  watch: {
    guid: {
      immediate: true,
      handler(value) {
        this.load(true);
      }
    }
  },
  mounted() {
      let _user = this.$store.state.auth.user; 
      if (_user) {
        this.isLabUser = ( this.$app.$data.Lab22Users.indexOf(_user.email) == -1 ) ? false : true;
      }

      if (!this.data && _app.$data.getSelectedDevice()?.guid == this.guid)
        this.updateData(_app.$data.getSelectedDevice());
        
      this.load();
  },
  computed: {
    tabs: function() {
      let setupRoute =  this.data?.modelId == 1 ? './setup' : './generic-setup';

      var tabs = [
        { name: 'Details', route: './details'},
        { name: 'Setup', route: setupRoute},
        { name: 'Status', route: './status'}
      ];

      if (this.$app.user.isAdmin()) // this.data?.modelId != 1
        tabs.push({ name: 'Components', route: './device-components'});

      tabs.push(
        { name: 'Alerts', route: './alerts'},        
        { name: 'Maintenance', route: './maintenance'}
      );

      return tabs;
    }
  },
  methods: {
    getSetupRoute() {
      console.log(this.data)
      return this.data?.modelId == 1 ? './setup' : './generic-setup';
    },
    addDeviceToHistory(guid, name) {
      let history = JSON.parse(localStorage.getItem('history')) || [],
          historyNew = [];
      if(history.length>0) { //remove current item if exists
        for(let i=0; i<history.length; i++) {
          if(history[i].guid!==guid && i<15)
            historyNew.push(history[i]);
        }
      }
      historyNew.unshift({'guid':guid, 'name':name}); //add current item to top of history
      localStorage.removeItem('history');
      localStorage.setItem('history', JSON.stringify(historyNew));
      _app.$data.viewHistory = [];
      _app.$data.viewHistory = history.filter(obj => {
                      return true;
                    });
    },
    load(reload) {
      const progress = reload ? this.$progress.start() : null;
      _app.$api.get('Device/GetDevice', { params: { guid: this.guid } }).then(
        result => {
          if (progress) progress.finish();

          this.updateData(result.data);

          this.getEvTypeStateData();
        },
        error => {
          if (progress) progress.finish();

          _app.handleError(error);
        }
      );
    },
    getEvTypeStateData() {      
      _app.$api.get('Device/GetDeviceEvTypeState', { params: { deviceId: this.data.id } }).then(
        result => {
          this.clientVersion = result.data.client_version;
        }
      );      
    },
    updateData(data) {

      this.data = data;

      //add to view history
      this.addDeviceToHistory(this.guid, data.name);

      //_app.$helper.resetObject(this.data, data);
      //this.$refs.routerView.device = this.data;
      this.statusName = _app.$data.DeviceStatus.getStatusName(data.statusId);

      if (_app.$data.deviceEditState?.id != this.data?.id) {
        _app.$data.deviceEditState = Object.assign({}, this.data);
        
        //_app.$data.setState('DeviceSetupState', {});
        _app.$helper.resetObject(_app.$data.getState('DeviceSetupState'), {
          deviceId: this.data.id,
          displayOn: true,
          touchScreenEnabled: true,          
          backlightLevel: 50,
          blueColorBalance: 50,
          brightness: 50,
          displayContrast: 50,
          gammaValue: 2.2,
          greenColorBalance: 50,
          redColorBalance: 50,
          
          //brightnessLevel: 7.5,
          backlightAutomaticStatus: true,
          ambientSensorMinBacklight: 50,
          ambientSensorMaxBacklight: 100,          
          ambientSensorMinAmbientLight: 0,
          ambientSensorMaxAmbientLight: 10000,
        
          powerSwitch1Status: true,
          powerSwitch2Status: true
        });

        if (_app.$data.hasState('DeviceStatus'))
          _app.$data.getState('DeviceStatus').dashboardGroups = [];
      }
      
      //_app.$helper.resetObject(this.$app.$data.deviceEditState, data);
      this.$app.$data.deviceEditState = data;

      this.$app.$data.clearStore('DEVICE_ALERT_LIST');
    },
    setStatus(statusId) {

      var statusName = _app.$data.DeviceStatus.getStatusName(statusId);
      
      _app.$modal.confirm('Change device status', `Are you sure you want to change status to ${statusName}?`, (confirmed) => {
        
        if (!confirmed) 
            return;
        
        let loader = this.$loading.show(); 
        
        this.$app.$api.get('Device/SetDeviceStatus', { params: { id: this.data.id, statusId: statusId } }).then(
          () => {
            loader.hide();
            this.data.statusId = statusId; 
            this.updateData(this.data);
            this.getEvTypeStateData();
          },
          () => {
            loader.hide();
          }
        );  
      });
    },
    deleteDevice() {
      _app.$modal.confirm('Delete device', `Are you sure you want to delete this device?`, (confirmed) => {
        
        if (!confirmed) 
            return;
        
        let loader = this.$loading.show(); 
        
        this.$app.$api.get('Device/DelDevice', { params: { id: this.data.id } }).then(
          () => {
            loader.hide();
            this.$router.replace({
              name: 'devices',
              params: { 
                reload: true
              }
            })
          },
          () => {
            loader.hide();
          }
        );  
      });
    },      
    downloadConfiguration() {
      //this.viewState.downloadingConfiguration = true;
      _app.downloadFile(`Device/DownloadConfiguration?id=${this.data.id}`).then(() => {
        //this.viewState.downloadingConfiguration = false;
      });
    },
    showOnMap() {
      this.$router.push({
        name: 'locations',
        params: { 
          filter: JSON.stringify({
            name: this.data.name
          })
        }
      })
    },
    refreshStatus() {
      
      _app.$api.get('Device/GetDevice', { params: { guid: this.guid } }).then(
        result => {
          this.data.statusId = result.data.statusId;
          this.statusName = _app.$data.DeviceStatus.getStatusName(result.data.statusId);
          this.data.connStatusId = result.data.connStatusId;
          this.data.lastEvent = result.data.lastEvent;
        },
        error => {
          _app.handleError(error);
        }
      );
    },
    deviceInfo(id) {
      console.log('Device id = ' + id);
    },
  } 
}
</script>

<style scoped>

.tabs {
  margin-top:20px;
}

.connected-status {
  width: 200px;
  margin-top: -12px;
}

.connected-status label {
  color: #bbc5d6;
  width: 70px;
}
</style>